<template>
    <div class="waiting white-back flex-1">
        <el-empty :image="imgPath" description="正在审核中，请耐心等待！" :image-size="467"/>
    </div>
</template>

<script>
    import imgPath from "@/assets/img/company_pass.png"
    export default {
        name: "waiting",
        data(){
            return {
                imgPath
            }
        }
    }
</script>

<style scoped>
    .waiting{padding-top: 9%;}
</style>